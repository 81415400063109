import {
	requests
}from '../../../../api/agent'

export default {
	createLevelReReport  : (params) => requests.post('/ReHierarchyLevelReReports/create', params),
	createLevelReReports : (params) => requests.post('/ReHierarchyLevelReReports/createReReports', params),
	deleteLevelReReport  : (params) => requests.post('/ReHierarchyLevelReReports/delete', params),
	deleteLevelReReports : (params) => requests.post('/ReHierarchyLevelReReports/deleteReReports', params),
	getAllPaged          : (params) => requests.getWithParams('/ReHierarchyLevelReReports/getAllPaged', params),
	updateLevelReReport  : (params) => requests.post('/ReHierarchyLevelReReports/update', params)
}
