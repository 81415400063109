import {
	reHierarchyLevelReReportConstants
}from "./constants"
import {
	defAction
}from "../../../../helpers/defaultAction"
import api from "./api"

export const reHierarchyLevelReReportActions = {
	clear,
	createLevelReReport,
	createLevelReReports,
	deleteLevelReReport,
	deleteLevelReReports,
	getAllPaged,
	getAllPagedExpand,
	updateLevelReReport
}

function clear(){
	return (dispatch) => {
		dispatch(request())
		function request(){
			return {
				type: reHierarchyLevelReReportConstants.GetAllPaged.CLEAR
			}
		}
	}
}

function createLevelReReports(values){
	const dispatchObj = {
		constants : reHierarchyLevelReReportConstants.CreateLevelReReports,
		service   : {
			func   : api.createLevelReReports,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function createLevelReReport(values){
	const dispatchObj = {
		constants : reHierarchyLevelReReportConstants.CreateLevelReReport,
		service   : {
			func   : api.createLevelReReport,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function deleteLevelReReport(values){
	const dispatchObj = {
		constants : reHierarchyLevelReReportConstants.DeleteLevelReReport,
		service   : {
			func   : api.deleteLevelReReport,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function deleteLevelReReports(values){
	const dispatchObj = {
		constants : reHierarchyLevelReReportConstants.DeleteLevelReReports,
		service   : {
			func   : api.deleteLevelReReports,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function getAllPaged(values){
	const dispatchObj = {
		constants : reHierarchyLevelReReportConstants.GetAllPaged,
		service   : {
			func   : api.getAllPaged,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function getAllPagedExpand(values){
	const dispatchObj = {
		constants : reHierarchyLevelReReportConstants.GetAllPagedExpand,
		service   : {
			func   : api.getAllPaged,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function updateLevelReReport(values){
	const dispatchObj = {
		constants : reHierarchyLevelReReportConstants.UpdateLevelReReport,
		service   : {
			func   : api.updateLevelReReport,
			params : values
		}
	}
	return defAction(dispatchObj)
}
