export const reFormTypesConstants = {
  SEND_REFORMTYPES_REQUEST: 'SEND_REFORMTYPES_REQUEST',
  SEND_REFORMTYPES_SUCCESS: 'SEND_REFORMTYPES_SUCCESS',
  SEND_REFORMTYPES_FAILURE: 'SEND_REFORMTYPES_FAILURE',
  getReFormTypes : {
    CLEAR: 'GET_FORM_TYPES_CLEAR',
  },
  createFormType : {
    REQUEST: 'CREATE_FORM_TYPES_REQUEST',
    SUCCESS: 'CREATE_FORM_TYPES_SUCCESS',
    FAILURE: 'CREATE_FORM_TYPES_FAILURE',
  },
  deleteFormType : {
    REQUEST: 'DELETE_FORM_TYPES_REQUEST',
    SUCCESS: 'DELETE_FORM_TYPES_SUCCESS',
    FAILURE: 'DELETE_FORM_TYPES_FAILURE',
  },
  updateFormType : {
    REQUEST: 'UPDATE_FORM_TYPES_REQUEST',
    SUCCESS: 'UPDATE_FORM_TYPES_SUCCESS',
    FAILURE: 'UPDATE_FORM_TYPES_FAILURE',
  },
  restoreFormType : {
    REQUEST: 'RESTORE_FORM_TYPES_REQUEST',
    SUCCESS: 'RESTORE_FORM_TYPES_SUCCESS',
    FAILURE: 'RESTORE_FORM_TYPES_FAILURE',
  }
}