export const reOrgsConstants = {
  SEND_REORGS_REQUEST: "SEND_REORGS_REQUEST",
  SEND_REORGS_SUCCESS: "SEND_REORGS_SUCCESS",
  SEND_REORGS_FAILURE: "SEND_REORGS_FAILURE",

  GetReOrgByThemesOrSubThemes: {
    REQUEST: "GET_REORG_BY_THEMES_OR_SUBTHEMES_REQUEST",
    SUCCESS: "GET_REORG_BY_THEMES_OR_SUBTHEMES_SUCCESS",
    FAILURE: "GET_REORG_BY_THEMES_OR_SUBTHEMES_FAILURE",
  },

  GetReOrgByUNP: {
    REQUEST: "GET_REORG_BY_UNP_REQUEST",
    SUCCESS: "GET_REORG_BY_UNP_SUCCESS",
    FAILURE: "GET_REORG_BY_UNP_FAILURE",
  },

  GetReOrgById: {
    REQUEST: "GET_REORG_BY_ID_REQUEST",
    SUCCESS: "GET_REORG_BY_ID_SUCCESS",
    FAILURE: "GET_REORG_BY_ID_FAILURE",
    CLEAR: "GET_REORG_BY_ID_CLEAR",
  },

  GetMinReOrgByNameOrUNP: {
    REQUEST: "GET_MIN_REORG_BY_UNP_REQUEST",
    SUCCESS: "GET_MIN_REORG_BY_UNP_SUCCESS",
    FAILURE: "GET_MIN_REORG_BY_UNP_FAILURE",
    CLEAR: "GET_MIN_REORG_BY_UNP_CLEAR",
  },
  GetReOrgsForApllicantsList: {
    REQUEST: "GET_REORGS_FOR_APLLICANTS_LIST_REQUEST",
    SUCCESS: "GET_REORGS_FOR_APLLICANTS_LIST_SUCCESS",
    FAILURE: "GET_REORGS_FOR_APLLICANTS_LIST_FAILURE",
    CLEAR: "GET_REORGS_FOR_APLLICANTS_LIST_CLEAR",
  },

  CheckReOrgIsRegistered: {
    REQUEST: "CHECK_REORG_IS_REGISTERED_REQUEST",
    SUCCESS: "CHECK_REORG_IS_REGISTERED_SUCCESS",
    FAILURE: "CHECK_REORG_IS_REGISTERED_FAILURE",
    CLEAR: "CHECK_REORG_IS_REGISTERED_CLEAR",
  },

  GetTypesStatuses: {
    REQUEST: "GET_REORG_TYPES_STATUSES_REQUEST",
    SUCCESS: "GET_REORG_TYPES_STATUSES_SUCCESS",
    FAILURE: "GET_REORG_TYPES_STATUSES_FAILURE",
    CLEAR: "GET_REORG_TYPES_STATUSES_CLEAR",
  },

  Create: {
    REQUEST: "CREATE_REORG_REQUEST",
    SUCCESS: "CREATE_REORG_SUCCESS",
    FAILURE: "CREATE_REORG_FAILURE",
  },

  Delete: {
    REQUEST: "DELETE_REORG_REQUEST",
    SUCCESS: "DELETE_REORG_SUCCESS",
    FAILURE: "DELETE_REORG_FAILURE",
  },

  Update: {
    REQUEST: "UPDATE_REORG_REQUEST",
    SUCCESS: "UPDATE_REORG_SUCCESS",
    FAILURE: "UPDATE_REORG_FAILURE",
  },

  CheckReOrgIsAvailable: {
    REQUEST: "CHECK_REORG_REQUEST",
    SUCCESS: "CHECK_REORG_SUCCESS",
    FAILURE: "CHECK_REORG_FAILURE",
    CLEAR: "CHECK_REORG_CLEAR",
  },
};
