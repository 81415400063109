import {
	requests
}from '../../../../api/agent'

export default {
	createReOrgReportReOrgs : (params) => requests.post('/ReOrgReportReOrgs/create', params),
	deleteReOrgReportReOrg  : (params) => requests.post('/ReOrgReportReOrgs/delete', params),
	getAllPaged             : (params) => requests.getWithParams('/ReOrgReportReOrgs/getAllPaged', params),
	getAllReOrgs            : (params) => requests.getWithParams('/ReOrgReportReOrgs/getAllReOrgs', params),
	isAnyReOrgs             : () => requests.get('/ReOrgReportReOrgs/isAnyReOrgs')
}
