import React, {
	useEffect, useState
}from "react"
import {
	Empty,
	Table,
	ConfigProvider,
	Popconfirm,
	Row
}from "antd"
import {
	useDebounce
}from "use-lodash-debounce"
import {
	reOrgReportReOrgActions
}from "./store/actions"
import {
	useDispatch, useSelector
}from "react-redux"
import "antd/lib/popconfirm/style/css.js"
import './style.css'
import '../../../pagination.css'
import i18n from "./localization"
import i18nGlobal from "../../../localization"
import ReOrgReportReOrgCreateForm from "./add"
import {
	CustomInput
}from "../../../components/CustomInput"
import CustomButton from '../../../components/ButtonCustom'
import Search from '../../../../public/search.svg'
import Plus from '../../../../public/Glyph_ .svg'
import Cross from '../../../../public/Cross.svg'
import LocalLoading from "../../../components/Loading/Local"
import UploadFromFile from "../ReOrgReReports/addFormFile"

const ReOrgReportReOrgs = (props) => {
	const { isModify = false, org, active } = props
	const pageSize = 10
	const [table, setTable] = useState([])
	const [page, setPage] = useState(1)
	const [searchValue, setSearchValue] = useState("")
	const [visibleAddForm, setVisibleAddForm] = useState(false)
	const searchWithPause = useDebounce(searchValue, 500)
	const dispatch = useDispatch()
	const isRu = useSelector((state) => state.globalReducer.isRu)
	const { isSending, reOrgReportReOrgsCreated, reOrgReportReOrgDeleted, reOrgReportReOrgs } = useSelector((state) => state.reOrgReportReOrgReducer)

	const columns = [
		{
			dataIndex : 'unp',
			editable  : false,
			inputType : 'text',
			key       : 'unp',
			title     : i18n.tableItems[isRu][0],
			width     : 100
		},
		{
			dataIndex : 'status',
			editable  : false,
			inputType : 'text',
			key       : 'status',
			title     : i18n.tableItems[isRu][1],
			width     : 200
		},
		{
			dataIndex : 'name',
			editable  : false,
			inputType : 'text',
			key       : 'index',
			render    : ((value, record) => {
				return isRu ? record.name : record.nameBel
			}),
			title : i18n.tableItems[isRu][2],
			width : 700
		},
		{
			dataIndex : 'type',
			editable  : false,
			inputType : 'text',
			key       : 'type',
			title     : i18n.tableItems[isRu][3],
			width     : 200
		}
	]

	isModify && columns.push({
		dataIndex : "delete",
		key       : "delete",
		render    : (id) => (
			<Popconfirm
				cancelText={ i18nGlobal.no[isRu] }
				okText={ i18nGlobal.yes[isRu] }
				title={ i18n.sureToDelete[isRu] }
				onCancel={ () => {
					return null
				} }
				onConfirm={ () => {
					deleteReOrgReportReOrg({
						id: id
					})
				} }
			>
				<img
					src={ Cross }
					title={ i18n.delete[isRu] }
				/>
			</Popconfirm>
		),
		width: 20
	})

	const getAllPaged = (params) => {
		dispatch(reOrgReportReOrgActions.getAllPaged(params))
	}
	const createReOrgReportReOrgs = (params) => {
		dispatch(reOrgReportReOrgActions.createReOrgReportReOrgs(params))
	}
	const deleteReOrgReportReOrg = (params) => {
		dispatch(reOrgReportReOrgActions.deleteReOrgReportReOrg(params))
	}

	useEffect(() => {
		return () => {
			setSearchValue("")
			setPage(1)
			dispatch(reOrgReportReOrgActions.clear())
		}
	}, [])

	useEffect(() => {
		if (reOrgReportReOrgs?.items){
			const temp = []
			if (reOrgReportReOrgs.items.length){
				reOrgReportReOrgs.items.forEach((value, index) => {
					temp.push({
						delete  : isModify && value.entityId,
						name    : value.fullName,
						nameBel : value.fullNameBel,
						rowKey  : index,
						status  : value.reOrgStatus,
						type    : value.reOrgType,
						unp     :	value.unp
					})
				})
			}
			else if (reOrgReportReOrgs.count > 0 && page > 1)
				onPaginationChange(page-1)
			setTable(temp)
		}
		else
			setTable([])
	}, [reOrgReportReOrgs])

	useEffect(() => {
		onPaginationChange(1)
	}, [searchWithPause, org])

	useEffect(() => {
		if (reOrgReportReOrgsCreated || reOrgReportReOrgDeleted)
			onPaginationChange(page)
	}, [reOrgReportReOrgsCreated, reOrgReportReOrgDeleted])

	function onPaginationChange(value){
		setPage(value)
		getAllPaged({
			name     : searchWithPause?.trim(),
			page     : value,
			pageSize : pageSize,
			reOrgId  : org?.id
		})
	}

	return (
		<>
			{ window.scrollTo(0, 0) }
			<ConfigProvider renderEmpty={ () => <Empty /> }>
				{ isModify && (
					<>
						<ReOrgReportReOrgCreateForm
							create={ createReOrgReportReOrgs }
							loading={ isSending }
							orgId={ org?.id }
							setVisible={ setVisibleAddForm }
							visible={ visibleAddForm }
						/>
						<Row className="rowMargin">
							<div className="addButton">
								<CustomButton
									disabled={ isSending }
									type="orange"
									onClick={ () => setVisibleAddForm(true) }
								>
									<img
										alt={ i18n.add[isRu] }
										src={ Plus }
									/>
									<span>
										{ i18n.add[isRu] }
									</span>
								</CustomButton>
							</div>
							{ active && (
								<div className="uploadReportOrgFile">
									<UploadFromFile />
								</div>
							) }
						</Row>
					</>
				) }
				<Row>
					<CustomInput
						allowClear
						maxLength={ 100 }
						placeholder={ i18n.inputSearch[isRu] }
						prefix={ <img src={ Search } /> }
						onChange={ (e) => setSearchValue(e.target.value) }
					/>
				</Row>
				<Row>
					<Table
						className="reOrgReportsReOrgs_table"
						columns={ columns }
						dataSource={ table }
						loading={ { indicator : <LocalLoading />,
							spinning  : isSending } }
						pagination={ {
							current         : page,
							onChange        : onPaginationChange,
							pageSize        : pageSize,
							position        : ['bottomCenter'],
							showSizeChanger : false,
							size           	: "small",
							total           : reOrgReportReOrgs?.count ?? 0
						} }
						rowKey={ (table) => table.rowKey }
						scroll={ { x: 1000 } }
					/>
				</Row>
			</ConfigProvider>
		</>
	)
}

export default ReOrgReportReOrgs
