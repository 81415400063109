import {
	reGosSubthemesConstants
}from './constants'

const initialState =
{
	isSending   : false,
	currentItem : null
}

export default function (state = initialState, action){
	switch (action.type){
		case reGosSubthemesConstants.SEND_REGOSSUBTHEMES_REQUEST:
			return {
				...state,
				isSending: true
			}
		case reGosSubthemesConstants.SEND_REGOSSUBTHEMES_SUCCESS:
			return {
				...state,
				isSending   : false,
				currentItem : action.payload
			}
		case reGosSubthemesConstants.SEND_REGOSSUBTHEMES_FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}
		case reGosSubthemesConstants.CLEAR:{
			return {
				...state,
				currentItem: null
			}
		}
		default:
			return state
	}
}