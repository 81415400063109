export const reHierarchyLevelsConstants = {
	CreateLevel: {
		FAILURE : 'CREATE_HIERARCHYLEVEL_FAILURE',
		REQUEST : 'CREATE_HIERARCHYLEVEL_REQUEST',
		SUCCESS : 'CREATE_HIERARCHYLEVEL_SUCCESS'
	},
	DeleteLevel: {
		FAILURE : 'DELETE_HIERARCHYLEVEL_FAILURE',
		REQUEST : 'DELETE_HIERARCHYLEVEL_REQUEST',
		SUCCESS : 'DELETE_HIERARCHYLEVEL_SUCCESS'
	},
	GetLevel: {
		FAILURE : 'GET_HIERARCHYLEVEL_FAILURE',
		REQUEST : 'GET_HIERARCHYLEVEL_REQUEST',
		SUCCESS : 'GET_HIERARCHYLEVEL_SUCCESS'
	},
	GetLevels: {
		CLEAR   : 'CLEAR_HIERARCHYLEVELS',
		FAILURE : 'GET_HIERARCHYLEVEL_BY_NAME_PAGED_FAILURE',
		REQUEST : 'GET_HIERARCHYLEVEL_BY_NAME_PAGED_REQUEST',
		SUCCESS : 'GET_HIERARCHYLEVEL_BY_NAME_PAGED_SUCCESS'
	},
	SetLevel: {
		FAILURE : 'SET_HIERARCHYLEVEL_FAILURE',
		REQUEST : 'SET_HIERARCHYLEVEL_REQUEST',
		SUCCESS : 'SET_HIERARCHYLEVEL_SUCCESS'
	},
	UpdateLevel: {
		FAILURE : 'UPDATE_HIERARCHYLEVEL_FAILURE',
		REQUEST : 'UPDATE_HIERARCHYLEVEL_REQUEST',
		SUCCESS : 'UPDATE_HIERARCHYLEVEL_SUCCESS'
	}
}
