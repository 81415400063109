import {
	defAction
}from "../../../../helpers/defaultAction"
import api from "./api"
import {
	reHierarchyLevelsConstants
}from "./constants"

export const reHierarchyLevelsActions = {
	clear,
	createLevel,
	deleteLevel,
	getLevel,
	getLevels,
	setLevel,
	updateLevel
}

function getLevels(params){
	const dispatchObj = {
		constants : reHierarchyLevelsConstants.GetLevels,
		service   : {
			func   : api.getLevels,
			params : params
		}
	}
	return defAction(dispatchObj)
}

function clear(){
	return (dispatch) => dispatch({
		type: reHierarchyLevelsConstants.GetLevels.CLEAR
	})
}

function createLevel(values){
	const dispatchObj = {
		constants : reHierarchyLevelsConstants.CreateLevel,
		service   : {
			func   : api.createLevel,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function deleteLevel(values){
	const dispatchObj = {
		constants : reHierarchyLevelsConstants.DeleteLevel,
		service   : {
			func   : api.deleteLevel,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function updateLevel(values){
	const dispatchObj = {
		constants : reHierarchyLevelsConstants.UpdateLevel,
		service   : {
			func   : api.updateLevel,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function getLevel(params){
	const dispatchObj = {
		constants : reHierarchyLevelsConstants.GetLevel,
		service   : {
			func   : api.getLevel,
			params : params
		}
	}
	return defAction(dispatchObj)
}

function setLevel(params){
	const dispatchObj = {
		constants : reHierarchyLevelsConstants.SetLevel,
		service   : {
			func   : api.setLevel,
			params : params
		}
	}
	return defAction(dispatchObj)
}