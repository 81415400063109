import React, {
	useState, useEffect, useCallback
}from "react"
import {
	Spin, Upload, Form, Modal, Checkbox
}from "antd"
import CustomButton from "../../../components/ButtonCustom"
import {
	useDispatch, useSelector
}from "react-redux"
import "antd/lib/upload/style/index.css"
import "antd/lib/spin/style/index.css"
import downloadFile from "../../../helpers/downloadFile"
import i18n from "./localization"
import File from '../../../../public/paper-clip.svg'
import './style.css'
import {
	reOrgReReportActions
}from "./store/actions"

const UploadReReportsOrgsFromFile = (props) => {
	const { isReports } = props
	const [fileResult, setFile] = useState({})
	const [form] = Form.useForm()
	const [isModalVisible, setIsModalVisible] = useState(false)
	const dispatch = useDispatch()
	const isRu = useSelector((state) => state.globalReducer.isRu)
	const { uploadFileLoading, uploadFileResult } = useSelector((state) => state.reOrgReReportReducer)
	const clearFile = useCallback(() => dispatch(reOrgReReportActions.clearFile()), [dispatch])
	const uploadReportLevels = useCallback((params) => {
		dispatch(reOrgReReportActions.uploadReportLevelsFromFile(params))
	}, [dispatch])
	const uploadReportOrgs = useCallback((params) => {
		dispatch(reOrgReReportActions.uploadReportOrgsFromFile(params))
	}, [dispatch])

	const propsUpload = {
		accept       : ".xls, .xlsx",
		beforeUpload : file => {
			setFile({ fileExtension : file.name.slice(file.name.lastIndexOf(".")),
				fileName      : `Результат загрузки ${ file.name.replace(file.name.slice(file.name.lastIndexOf(".")), "") }`,
				type          : file.type })
			const formData = new FormData()
			formData.append("File", file)
			formData.append("IsSave", Boolean(form.getFieldValue("IsSave")))
			isReports ? uploadReportLevels(formData) : uploadReportOrgs(formData)
			return Upload.LIST_IGNORE
		},
		maxCount : 1,
		multiple : false
	}

	useEffect(() => {
		if (uploadFileResult){
			downloadFile(uploadFileResult, fileResult, true)
			clearFile()
		}
	}, [uploadFileResult])

	return (
		<div className="uploadUsersItem">
			<CustomButton
				type="orange"
				onClick={ () => {
					setIsModalVisible(true)
				} }
			>
				{ i18n.uploadHeaders[isRu][isReports ? 1 : 2] }
			</CustomButton>
			<Modal
				destroyOnClose
				footer={ null }
				title={ i18n.uploadHeaders[isRu][isReports ? 1 : 2] }
				visible={ isModalVisible }
				width={ 600 }
				onCancel={ () => {
					if (!uploadFileLoading){
						clearFile()
						setIsModalVisible(false)
					}
				} }
			>
				<Spin
					spinning={ uploadFileLoading }
					tip={ i18n.tipLoading[isRu] }
				>
					<Form
						form={ form }
						id="form"
						labelAlign="left"
						layout="vertical"
					>
						<Form.Item
							initialValue
							label={ i18n.isSave[isRu] }
							name="IsSave"
							valuePropName="checked"
						>
							<Checkbox disabled={ uploadFileLoading } />
						</Form.Item>
						<Form.Item name="uploadFile">
							<Upload
								{ ...propsUpload }
								className="upload-user-custom"
								disabled={ uploadFileLoading }
							>
								<div className="add-file">
									<img
										alt={ i18n.uploadHeaders[isRu][0] }
										src={ File }
									/>
									<span>
										{ i18n.uploadFile[isRu] }
									</span>
								</div>
							</Upload>
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</div>
	)
}

export default UploadReReportsOrgsFromFile
