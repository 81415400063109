import {
	reCountriesConstants
} from './constants'
import {
	userConstants
} from "../../../store/constants/user.constants"
import {
	appealsConstants
} from '../../Appeals/store/constants'
import { i18n } from '../localization'
import isRu from '../../../helpers/isRu'

const initialState =
{
	isSending: false,
	currentItem: {},
	countryChanged: null,
	error: null
}

export default function (state = initialState, action) {
	switch (action.type) {
		case userConstants.Logout.SUCCESS:
			return {
				...state,
				isSending: false,
				currentItem: {}
			}
		case appealsConstants.SET_FILTERS: {
			return {
				...state,
				currentItemOld: state.currentItem
			}
		}
		case reCountriesConstants.SEND_RECOUNTRIES_REQUEST:
			return {
				...state,
				isSending: true
			}
		case reCountriesConstants.SEND_RECOUNTRIES_SUCCESS:
			return {
				...state,
				isSending: false,
				currentItem: action.payload
			}
		case reCountriesConstants.SEND_RECOUNTRIES_FAILURE:
			return {
				...state,
				isSending: false,
				error: action.payload
			}
		case reCountriesConstants.createCountry.REQUEST:
		case reCountriesConstants.deleteCountry.REQUEST:
		case reCountriesConstants.restoreCountry.REQUEST:
		case reCountriesConstants.updateCountry.REQUEST:
			return {
				...state,
				isSending: true,
				countryChanged: null
			}
		case reCountriesConstants.createCountry.FAILURE:
		case reCountriesConstants.deleteCountry.FAILURE:
		case reCountriesConstants.restoreCountry.FAILURE:
		case reCountriesConstants.updateCountry.FAILURE:
			return {
				...state,
				isSending: false,
				countryChanged: null,
				error: action.payload
			}
		case reCountriesConstants.createCountry.SUCCESS:
			return {
				...state,
				isSending: false,
				countryChanged: action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null
			}
		case reCountriesConstants.deleteCountry.SUCCESS:
			return {
				...state,
				isSending: false,
				countryChanged: action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null
			}
		case reCountriesConstants.restoreCountry.SUCCESS:
			return {
				...state,
				isSending: false,
				countryChanged: action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null
			}
		case reCountriesConstants.updateCountry.SUCCESS:
			return {
				...state,
				isSending: false,
				countryChanged: action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null
			}
		case reCountriesConstants.getReCountries.CLEAR:
			return {
				...state,
				isSending: false,
				countryChanged: null,
				currentItem: {}
			}	
		default:
			return state
	}
}