const i18n = {
	tableItems: {
		1: [
			"Индекс",
			"Регион",
			"Район",
			"Населенный пункт",
			"Улица",
			"Номера домов",
			"Дата начала действия",
			"Дата окончания действия"
		],
		0: [
			"Індэкс",
			"Рэгіён",
			"Раён",
			"Назва населенага пункта",
			"Назва вуліцы",
			"Нумары дамоў",
			"Дата пачатку дзеяння",
			"Дата заканчэння тэрміну дзеяння"
		]
	},
	formLocale: {
		1: [
			"Название населенного пункта:",
			"Введите название населенного пункта",
			"Название улицы:",
			"Введите название улицы"
		],
		0: [
			"Назва населенага пункта:",
			"Калі ласка, увядзіце назву населенага пункта",
			"Назва вуліцы:",
			"Калі ласка, увядзіце назву вуліцы"
		]
	},
	inputIndex: {
		1 : ["Введите индекс"],
		0 : ["Увядзіце індекс"]
	},
	successChangeData: {
		1 : "Данные успешно изменены.",
		0 : "Даныя паспяхова зменены."
	},
	successRestoreData: {
		1 : "Запись восстановлена.",
		0 : "Запіс адноўлены."
	},
	sureToDelete: {
		1 : "Вы уверены, что хотите удалить индекс?",
		0 : "Вы ўпэўненыя, што хочаце выдаліць індэкс?"
	},
	sureToRestore: {
		1 : "Вы уверены, что хотите восстановить Индекс?",
		0 : "Вы ўпэўненыя, што хочаце аднавіць Індэкс?"
	},
	restore: {
		1 : "Восстановить",
		0 : "Аднавіць"
	},
	delete: {
		1 : "Удалить",
		0 : "Выдаліць"
	},
	update: {
		1 : "Редактировать",
		0 : "Рэдагаваць"
	},
	add: {
		1 : "Добавить",
		0 : "Дадаць"
	},
	validateMessageIndex: {
		1 : "Поле 'Индекс' заполнено неккоректно.",
		0 : "Поле 'Індэкс' запоўнена некарэктна."
	},
	validateMessageRange: {
		1 : "В данное поле  нужно ввести 6 символов.",
		0 : "У дазенае поле  патрэбна ўвесці  6 сімвалаў."
	},
	headerIndexFormCreate: {
		1 : "Добавить запись",
		0 : "Дадаць запіс"
	},
	headerIndexFormUpdate: {
		1 : "Редактировать запись",
		0 : "Рэдагаваць запіс"
	},
	startDateValidateMessage: {
		1 : ["Пожалуйста, введите дату начала действия"],
		0 : ["Калі ласка, увядзіце дату пачатку дзеяння "]
	},
	dataNotChanged: {
		1 : ["Введенные вами данные полностью соответствуют изначальным."],
		0 : ["Уведзеныя вамі дадзеныя цалкам адпавядаюць першапачатковым."]
	},
	dataDeleted: {
		1 : "Запись успешно удалена",
		0 : "Запіс паспяхова выдалены"
	},
	dataCreated: {
		1 : "Индекс успешно добавлен",
		0 : "Індэкс паспяхова дададзены "
	},
	dataUpdated: {
		1 : "Информация о индексе успешно изменена",
		0 : "Інфармацыя аб індэксе паспяхова зменена"
	}

}
export default i18n