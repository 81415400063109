export const controlCompleteStatusConstants = {
    getList: {
		FAILURE : 'GET_LIST_CONTROL_COMPLETE_STATUS_FAILURE',
		REQUEST : 'GET_LIST_CONTROL_COMPLETE_STATUS_REQUEST',
		SUCCESS : 'GET_LIST_CONTROL_COMPLETE_STATUS_SUCCESS',
	},
    getPaged: {
		FAILURE : 'GET_PAGED_CONTROL_COMPLETE_STATUS_FAILURE',
		REQUEST : 'GET_PAGED_CONTROL_COMPLETE_STATUS_REQUEST',
		SUCCESS : 'GET_PAGED_CONTROL_COMPLETE_STATUS_SUCCESS',
	},
    create: {
		FAILURE : 'CREATE_CONTROL_COMPLETE_STATUS_FAILURE',
		REQUEST : 'CREATE_CONTROL_COMPLETE_STATUS_REQUEST',
		SUCCESS : 'CREATE_CONTROL_COMPLETE_STATUS_SUCCESS'
	},
    delete: {
		FAILURE : 'DELETE_CONTROL_COMPLETE_STATUS_FAILURE',
		REQUEST : 'DELETE_CONTROL_COMPLETE_STATUS_REQUEST',
		SUCCESS : 'DELETE_CONTROL_COMPLETE_STATUS_SUCCESS'
	},
    update: {
		FAILURE : 'UPDATE_CONTROL_COMPLETE_STATUS_FAILURE',
		REQUEST : 'UPDATE_CONTROL_COMPLETE_STATUS_REQUEST',
		SUCCESS : 'UPDATE_CONTROL_COMPLETE_STATUS_SUCCESS'
	},
	restore: {
		FAILURE : 'RESTORE_CONTROL_COMPLETE_STATUS_FAILURE',
		REQUEST : 'RESTORE_CONTROL_COMPLETE_STATUS_REQUEST',
		SUCCESS : 'RESTORE_CONTROL_COMPLETE_STATUS_SUCCESS'
	},
	clear: 'CONTROL_COMPLETE_STATUS_CLEAR'
}