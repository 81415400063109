import {
	reOrgReReportConstants
}from "./constants"
import notice from "../../../../components/Notice"
import i18n from "../localization"
import isRu from "../../../../helpers/isRu"

const initialState = {
	isSending             : false,
	kindsReOrgReports     : [],
	reOrgReReportCreated  : false,
	reOrgReReportDeleted  : false,
	reOrgReReportUpdated  : false,
	reOrgReReports        : [],
	reOrgReReportsAll     : [],
	reOrgReReportsCreated : false,
	reOrgReReportsDeleted : false,
	uploadFileLoading     : false,
	uploadFileResult      : null
}

export default function (state = initialState, action){
	switch (action.type){
		case reOrgReReportConstants.CreateReOrgReReport.REQUEST :
			return {
				...state,
				isSending            : true,
				reOrgReReportCreated : false
			}
		case reOrgReReportConstants.CreateReOrgReReport.SUCCESS :
			notice("success", i18n.dataCreated[isRu()])
			return {
				...state,
				isSending            : false,
				reOrgReReportCreated : action.payload.result
			}
		case reOrgReReportConstants.CreateReOrgReReport.FAILURE :
			return {
				...state,
				error                : action.payload,
				isSending            : false,
				reOrgReReportCreated : false
			}
		case reOrgReReportConstants.CreateReOrgReReports.REQUEST :
			return {
				...state,
				isSending             : true,
				reOrgReReportsCreated : false
			}
		case reOrgReReportConstants.CreateReOrgReReports.SUCCESS :
			notice("success", i18n.dataCreated[isRu()])
			return {
				...state,
				isSending             : false,
				reOrgReReportsCreated : action.payload.result
			}
		case reOrgReReportConstants.CreateReOrgReReports.FAILURE :
			return {
				...state,
				error                 : action.payload,
				isSending             : false,
				reOrgReReportsCreated : false
			}
		case reOrgReReportConstants.DeleteReOrgReReport.REQUEST :
			return {
				...state,
				isSending            : true,
				reOrgReReportDeleted : false
			}
		case reOrgReReportConstants.DeleteReOrgReReport.SUCCESS :
			notice("success", i18n.dataDeleted[isRu()])
			return {
				...state,
				isSending            : false,
				reOrgReReportDeleted : action.payload.result
			}
		case reOrgReReportConstants.DeleteReOrgReReport.FAILURE :
			return {
				...state,
				error                : action.payload,
				isSending            : false,
				reOrgReReportDeleted : false
			}
		case reOrgReReportConstants.DeleteReOrgReReports.REQUEST :
			return {
				...state,
				isSending             : true,
				reOrgReReportsDeleted : false
			}
		case reOrgReReportConstants.DeleteReOrgReReports.SUCCESS :
			notice("success", i18n.dataDeleted[isRu()])
			return {
				...state,
				isSending             : false,
				reOrgReReportsDeleted : action.payload.result
			}
		case reOrgReReportConstants.DeleteReOrgReReports.FAILURE :
			return {
				...state,
				error                 : action.payload,
				isSending             : false,
				reOrgReReportsDeleted : false
			}
		case reOrgReReportConstants.UpdateReOrgReReport.REQUEST :
			return {
				...state,
				isSending            : true,
				reOrgReReportUpdated : false
			}
		case reOrgReReportConstants.UpdateReOrgReReport.SUCCESS :
			notice("success", i18n.dataUpdated[isRu()])
			return {
				...state,
				isSending            : false,
				reOrgReReportUpdated : action.payload.result
			}
		case reOrgReReportConstants.UpdateReOrgReReport.FAILURE :
			return {
				...state,
				error                : action.payload,
				isSending            : false,
				reOrgReReportUpdated : false
			}
		case reOrgReReportConstants.GetAllPaged.REQUEST :
			return {
				...state,
				isSending             : true,
				reOrgReReportCreated  : false,
				reOrgReReportDeleted  : false,
				reOrgReReportUpdated  : false,
				reOrgReReportsCreated : false,
				reOrgReReportsDeleted : false
			}
		case reOrgReReportConstants.GetAllPaged.SUCCESS :
			return {
				...state,
				isSending      : false,
				reOrgReReports : action.payload.result
			}
		case reOrgReReportConstants.GetAllPaged.FAILURE :
			return {
				...state,
				error          : action.payload,
				isSending      : false,
				reOrgReReports : []
			}
		case reOrgReReportConstants.GetActualKindsReOrgReports.REQUEST :
			return {
				...state,
				isSending: true
			}
		case reOrgReReportConstants.GetActualKindsReOrgReports.SUCCESS :
			return {
				...state,
				isSending         : false,
				kindsReOrgReports : action.payload.result
			}
		case reOrgReReportConstants.GetActualKindsReOrgReports.FAILURE :
			return {
				...state,
				error             : action.payload,
				isSending         : false,
				kindsReOrgReports : []
			}
		case reOrgReReportConstants.GetAllPaged.CLEAR :
			return {
				...state,
				isSending         : false,
				kindsReOrgReports : [],
				reOrgReReports    : [],
				reOrgReReportsAll : []
			}
		case reOrgReReportConstants.GetAllReports.REQUEST :
			return {
				...state,
				isSending: true
			}
		case reOrgReReportConstants.GetAllReports.SUCCESS :
			return {
				...state,
				isSending         : false,
				reOrgReReportsAll : action.payload.result
			}
		case reOrgReReportConstants.GetAllReports.FAILURE :
			return {
				...state,
				error             : action.payload,
				isSending         : false,
				reOrgReReportsAll : []
			}
		case reOrgReReportConstants.UploadFromFile.CLEAR :
			return {
				...state,
				uploadFileLoading : false,
				uploadFileResult  : null
			}
		case reOrgReReportConstants.UploadFromFile.REQUEST :
			return {
				...state,
				uploadFileLoading: true
			}
		case reOrgReReportConstants.UploadFromFile.SUCCESS :
			return {
				...state,
				uploadFileLoading : false,
				uploadFileResult  : action.payload.result
			}
		case reOrgReReportConstants.UploadFromFile.FAILURE :
			return {
				...state,
				error             : action.payload,
				uploadFileLoading : false,
				uploadFileResult  : null
			}
		default :
			return state
	}
}
