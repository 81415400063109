const i18n = {
	add: {
		0 : "Дадаць",
		1 : "Добавить"
	},
	createFormOrgsLabel: {
		0 : "Арганізацыі",
		1 : "Организации"
	},
	createFormOrgsRule: {
		0 : "Патрабуецца выбраць арганізацыю(і)!",
		1 : "Требуется выбрать организацию(и)!"
	},
	dataCreated: {
		0 : "Запіс паспяхова дададзены.",
		1 : "Запись успешно добавлена."
	},
	dataDeleted: {
		0 : "Запіс паспяхова выдалены.",
		1 : "Запись успешно удалена."
	},
	dataSearch: {
		0 : "Пожалуйста, подождите, идёт получение данных...",
		1 : "Калі ласка, пачакайце, ідзе атрыманне дадзеных..."
	},
	delete: {
		0 : "Выдаліць",
		1 : "Удалить"
	},
	headerCreateForm: {
		0 : "Дадаць запіс",
		1 : "Добавить запись"
	},
	inputSearch: {
		0 : 'Пачніце уводзіць назву ці УНП арганізацыі. Пошук пачынаецца з трох знакаў',
		1 : 'Начните вводить название или УНП организации. Поиск начинается с трех символов'
	},
	inputSearchAll: {
		0 : 'Пачніце уводзіць назву ці УНП арганізацыі-стваральніка',
		1 : 'Начните вводить название или УНП организации-создателя'
	},
	isInstitution: {
		0 : 'Усе падведамныя',
		1 : 'Все подведомственные'
	},
	isParent: {
		0 : 'Усе даччыныя',
		1 : 'Все дочерние'
	},
	selectOrgEmpty: {
		0 : "Арганізацыі не знойдзеныя",
		1 : "Организации не найдены"
	},
	selectOrgPlaceholder: {
		0 : "Выберыце арганізацыю (пачніце уводзіць назву ці УНП)",
		1 : "Выберите организацию (начните вводить название или УНП)"
	},
	sureToDelete: {
		0 : "Вы ўпэўненыя, што хочаце выдаліць запіс?",
		1 : "Вы уверены, что хотите удалить запись?"
	},
	tableItems: {
		0: [
			"УНП",
			"Статус",
			"Назва",
			"Тып"
		],
		1: [
			"УНП",
			"Статус",
			"Название",
			"Тип"
		]
	},
	tableItemsAll: {
		0 : ["Арганізацыя-стваральнік", "Арганізацыі, ад асобы якіх ствараюцца справаздачы"],
		1 : ["Организация-создатель", "Организации, от лица которых создаются отчёты"]
	}

}
export default i18n