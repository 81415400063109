export const tasksConstants = {
	GetOwnTasks: {
		REQUEST : 'GET_OWN_TASKS_REQUEST',
		SUCCESS : 'GET_OWN_TASKS_SUCCESS',
		FAILURE : 'GET_OWN_TASKS_FAILURE'
	},
	GetOwnOrgTasks: {
		REQUEST : 'GET_OWN_ORG_TASKS_REQUEST',
		SUCCESS : 'GET_OWN_ORG_TASKS_SUCCESS',
		FAILURE : 'GET_OWN_ORG_TASKS_FAILURE'
	},
	GetSubOrgTasks: {
		REQUEST : 'GET_SUB_ORG_TASKS_REQUEST',
		SUCCESS : 'GET_SUB_ORG_TASKS_SUCCESS',
		FAILURE : 'GET_SUB_ORG_TASKS_FAILURE'
	},
	GetAllStatuses: {
		REQUEST : 'GET_ALL_STATUSES_REQUEST',
		SUCCESS : 'GET_ALL_STATUSES_SUCCESS',
		FAILURE : 'GET_ALL_STATUSES_FAILURE'
	},
	GetAllTypes: {
		REQUEST : 'GET_ALL_TYPES_REQUEST',
		SUCCESS : 'GET_ALL_TYPES_SUCCESS',
		FAILURE : 'GET_ALL_TYPES_FAILURE'
	},
	GetAllControls: {
		REQUEST : 'GET_ALL_CONTROLS_REQUEST',
		SUCCESS : 'GET_ALL_CONTROLS_SUCCESS',
		FAILURE : 'GET_ALL_CONTROLS_FAILURE'
	},
	DeleteTask: {
		REQUEST : 'DELETE_TASK_REQUEST',
		SUCCESS : 'DELETE_TASK_SUCCESS',
		FAILURE : 'DELETE_TASK_FAILURE',
		CLEAR   : 'DELETE_TASK_CLEAR'
	}
}