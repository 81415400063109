import {
	controlCompleteStatusConstants
}from "./constants"

const initialState = {
	controlCompleteStatusPaged : null,
	error                      : null,
	isSending                  : false,
	controlCompleteStatusList  : null,
    changeableStatusMessage    : null,
}

export default function (state = initialState, action){
	switch (action.type){
		case controlCompleteStatusConstants.getList.REQUEST:
			return {
				...state,
				isSending: true,
                controlCompleteStatusList : null
			}
		case controlCompleteStatusConstants.getList.SUCCESS:
			return {
				...state,
				isSending   : false,
			    controlCompleteStatusList : action.payload.result
			}
        case controlCompleteStatusConstants.getPaged.REQUEST:
            return {
                ...state,
                controlCompleteStatusPaged: null,
                isSending: true
            }
        case controlCompleteStatusConstants.getPaged.SUCCESS:
            return {
                ...state,
                isSending   : false,
                controlCompleteStatusPaged : action.payload.result
            }

        case controlCompleteStatusConstants.create.REQUEST:
        case controlCompleteStatusConstants.update.REQUEST:
        case controlCompleteStatusConstants.delete.REQUEST:
        case controlCompleteStatusConstants.restore.REQUEST:
            return {
                ...state,
                isSending : true,
                changeableStatusMessage : null,
            }
        case controlCompleteStatusConstants.create.SUCCESS:
        case controlCompleteStatusConstants.update.SUCCESS:
        case controlCompleteStatusConstants.delete.SUCCESS:
        case controlCompleteStatusConstants.restore.SUCCESS:
            return {
                ...state,
                isSending : false,
                changeableStatusMessage : action.payload.result
            }
        case controlCompleteStatusConstants.create.FAILURE:
        case controlCompleteStatusConstants.update.FAILURE:
        case controlCompleteStatusConstants.delete.FAILURE:
        case controlCompleteStatusConstants.restore.FAILURE:
        case controlCompleteStatusConstants.getList.FAILURE:
        case controlCompleteStatusConstants.getPaged.FAILURE:
            return {
                ...state,
                isSending : false,
                error : action.payload.result,
            }
        case controlCompleteStatusConstants.clear:
            return {
                ...state,
                controlCompleteStatusList  : null,
                changeableStatusMessage    : null,
                error                      : null,
                isSending                  : false,
                controlCompleteStatusPaged : null,
            }
		default:
			return state
	}
}
