const i18n = {
	add: {
		0 : "Дадаць",
		1 : "Добавить"
	},
	changeLevelPlaceholder: {
		0 : "Выберыце ўзровень іерархіі",
		1 : "Выберите уровень иерархии"
	},
	createFormReportsLabel: {
		0 : "Справаздачы",
		1 : "Отчёты"
	},
	createFormReportsPlaceholder: {
		0 : 'Пачніце уводзіць назву ці код справаздач. Пошук пачынаецца з трох знакаў',
		1 : 'Начните вводить название или код отчётов. Поиск начинается с трех символов'
	},
	createFormReportsRule: {
		0 : "Патрабуецца выбраць справаздачу(ы)!",
		1 : "Требуется выбрать отчёт(ы)!"
	},
	dataCreated: {
		0 : "Запіс паспяхова дададзены.",
		1 : "Запись успешно добавлена."
	},
	dataDeleted: {
		0 : "Запіс паспяхова выдалены.",
		1 : "Запись успешно удалена."
	},
	dataSearch: {
		0 : "Калі ласка, пачакайце, ідзе атрыманне дадзеных...",
		1 : "Пожалуйста, подождите, идёт получение данных..."
	},
	dataUpdated: {
		0 : "Інфармацыя паспяхова зменена.",
		1 : "Информация успешно изменена."
	},
	delete: {
		0 : "Выдаліць",
		1 : "Удалить"
	},
	headerCreateForm: {
		0 : "Дадаць запіс",
		1 : "Добавить запись"
	},
	headerUpdateForm: {
		0 : "Рэдагаваць запіс",
		1 : "Редактировать запись"
	},
	helpHierarchy: {
		0 : "Пры ручным даданні справаздач ўзровень іерархіі выдаляецца. Пры змене ўзроўню іерархіі ўсе справаздачы, выстаўленыя ручным спосабам, выдаляюцца. Узровень іерархіі па змаўчанні для ўсіх арганізацый: з кодам 1",
		1 : "При ручном добавлении отчётов уровень иерархии удаляется. При изменении уровня иерархии все отчёты, выставленные ручным способом, удаляются. Уровень иерархии по умолчанию для всех организаций: с кодом 1"
	},
	infoOnly: {
		0 : "Толькі для прагляду",
		1 : "Только для просмотра"
	},
	inputSearch: {
		0 : 'Пачніце ўводзіць адзін з параметраў справаздачы: Код | Назва',
		1 : 'Начните вводить один из параметров отчёта: Код | Название'
	},
	inputSearchAll: {
		0 : 'Пачніце уводзіць назву ці УНП арганізацыі-стваральніка',
		1 : 'Начните вводить название или УНП организации-создателя'
	},
	isForAllOrgs: {
		0 : 'Выбар любой арганізацыі',
		1 : 'Выбор любой организации'
	},
	isSave: {
		0 : "Загрузка у БД",
		1 : "Загрузка в БД"
	},
	reportsEmpty: {
		0 : "Справаздачы не знойдзеныя",
		1 : "Отчёты не найдены"
	},
	selectLevelPlaceholder: {
		0 : "Дадаць справаздачы з ўзроўню іерархіі",
		1 : "Добавить отчёты из уровня иерархии"
	},
	selectOrgEmpty: {
		0 : "Арганізацыі не знойдзеныя",
		1 : "Организации не найдены"
	},
	selectOrgPlaceholder: {
		0 : "Выберыце арганізацыю (пачніце уводзіць назву ці УНП)",
		1 : "Выберите организацию (начните вводить название или УНП)"
	},
	sureToDelete: {
		0 : "Вы ўпэўненыя, што хочаце выдаліць запіс?",
		1 : "Вы уверены, что хотите удалить запись?"
	},
	tableItems: {
		0: [
			'Выгляд',
			'Назва',
			'Назва па-беларуску',
			'Код',
			'Характарыстыкі'
		],
		1: [
			'Вид',
			'Название',
			'Название на белорусском',
			'Код',
			'Характеристики'
		]
	},
	tableItemsAll: {
		0: [
			"Арганізацыя-стваральнік",
			"Справаздачы",
			"Узровень іерархіі справаздач"
		],
		1: [
			"Организация-создатель",
			"Отчёты",
			"Уровень иерархии отчётов"
		]
	},
	tabsNames: {
		0 : ["Пералік справаздач", "Пералік арганізацый"],
		1 : ["Перечень отчётов", "Перечень организаций"]
	},
	tipLoading: {
		0 : "Пачакайце, ідзе загрузка...",
		1 : "Подождите, идет загрузка..."
	},
	update: {
		0 : "Рэдагаваць",
		1 : "Редактировать"
	},
	uploadFile: {
		0 : 'Загрузіць файл',
		1 : 'Загрузить файл'
	},
	uploadHeaders: {
		0: [
			"Загрузіць",
			"Загрузіць ўзроўні іерархіі",
			"загрузіць арганізацыі"
		],
		1: [
			"Загрузить",
			"Загрузить уровни иерархии",
			"Загрузить организации"
		]
	}
}
export default i18n