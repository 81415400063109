export const reOrgReReportConstants = {
	CreateReOrgReReport: {
		FAILURE : "CREATE_REORGREREPORT_FAILURE",
		REQUEST : "CREATE_REORGREREPORT_REQUEST",
		SUCCESS : "CREATE_REORGREREPORT_SUCCESS"
	},
	CreateReOrgReReports: {
		FAILURE : "CREATE_REORGREREPORTS_FAILURE",
		REQUEST : "CREATE_REORGREREPORTS_REQUEST",
		SUCCESS : "CREATE_REORGREREPORTS_SUCCESS"
	},
	DeleteReOrgReReport: {
		FAILURE : "DELETE_REORGREREPORT_FAILURE",
		REQUEST : "DELETE_REORGREREPORT_REQUEST",
		SUCCESS : "DELETE_REORGREREPORT_SUCCESS"
	},
	DeleteReOrgReReports: {
		FAILURE : "DELETE_REORGREREPORTS_FAILURE",
		REQUEST : "DELETE_REORGREREPORTS_REQUEST",
		SUCCESS : "DELETE_REORGREREPORTS_SUCCESS"
	},
	GetActualKindsReOrgReports: {
		FAILURE : "GET_ACTUALKINDSREORGREPORTS_FAILURE",
		REQUEST : "GET_ACTUALKINDSREORGREPORTS_All_REQUEST",
		SUCCESS : "GET_ACTUALKINDSREORGREPORTS_All_SUCCESS"
	},
	GetAllPaged: {
		CLEAR   : "CLEAR_REORGREREPORT_ALL_PAGED",
		FAILURE : "GET_REORGREREPORT_All_PAGED_FAILURE",
		REQUEST : "GET_REORGREREPORT_All_PAGED_REQUEST",
		SUCCESS : "GET_REORGREREPORT_All_PAGED_SUCCESS"
	},
	GetAllReports: {
		FAILURE : "GET_REORGREREPORT_All_FAILURE",
		REQUEST : "GET_REORGREREPORT_All_REQUEST",
		SUCCESS : "GET_REORGREREPORT_All_SUCCESS"
	},
	UpdateReOrgReReport: {
		FAILURE : "UPDATE_REORGREREPORT_FAILURE",
		REQUEST : "UPDATE_REORGREREPORT_REQUEST",
		SUCCESS : "UPDATE_REORGREREPORT_SUCCESS"
	},
	UploadFromFile: {
		CLEAR   : "CLEAR_REREPORTORG_FILE",
		FAILURE : "REREPORTORG_UPLOAD_FROM_FILE_FAILURE",
		REQUEST : "REREPORTORG_UPLOAD_FROM_FILE_REQUEST",
		SUCCESS : "REREPORTORG_UPLOAD_FROM_FILE_SUCCESS"
	}
}