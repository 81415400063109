import {
	requests
}from '../../../../api/agent'

export default {
	createLevel : (params) => requests.post('/ReHierarchyLevels/create', params),
	deleteLevel : (params) => requests.post('/ReHierarchyLevels/delete', params),
	getLevel    : (params) => requests.getWithParams('/ReHierarchyLevels/getLevel', params),
	getLevels   : (params) => requests.getWithParams('/ReHierarchyLevels/getAllPaged', params),
	setLevel    : (params) => requests.getWithParams('/ReHierarchyLevels/setLevel', params),
	updateLevel : (params) => requests.post('/ReHierarchyLevels/update', params)
}
