import {
	reOrgReportReOrgConstants
}from "./constants"
import notice from "../../../../components/Notice"
import i18n from "../localization"
import isRu from "../../../../helpers/isRu"

const initialState = {
	isAnyReOrgs              : false,
	isSending                : false,
	reOrgReportReOrgDeleted  : false,
	reOrgReportReOrgs        : [],
	reOrgReportReOrgsAll     : [],
	reOrgReportReOrgsCreated : false
}

export default function (state = initialState, action){
	switch (action.type){
		case reOrgReportReOrgConstants.CreateReOrgReportReOrgs.REQUEST :
			return {
				...state,
				isSending                : true,
				reOrgReportReOrgsCreated : false
			}
		case reOrgReportReOrgConstants.CreateReOrgReportReOrgs.SUCCESS :
			notice("success", i18n.dataCreated[isRu()])
			return {
				...state,
				isSending                : false,
				reOrgReportReOrgsCreated : action.payload.result
			}
		case reOrgReportReOrgConstants.CreateReOrgReportReOrgs.FAILURE :
			return {
				...state,
				error                    : action.payload,
				isSending                : false,
				reOrgReportReOrgsCreated : false
			}
		case reOrgReportReOrgConstants.DeleteReOrgReportReOrg.REQUEST :
			return {
				...state,
				isSending               : true,
				reOrgReportReOrgDeleted : false
			}
		case reOrgReportReOrgConstants.DeleteReOrgReportReOrg.SUCCESS :
			notice("success", i18n.dataDeleted[isRu()])
			return {
				...state,
				isSending               : false,
				reOrgReportReOrgDeleted : action.payload.result
			}
		case reOrgReportReOrgConstants.DeleteReOrgReportReOrg.FAILURE :
			return {
				...state,
				error                   : action.payload,
				isSending               : false,
				reOrgReportReOrgDeleted : false
			}
		case reOrgReportReOrgConstants.GetAllPaged.REQUEST :
			return {
				...state,
				isSending                : true,
				reOrgReportReOrgDeleted  : false,
				reOrgReportReOrgsCreated : false
			}
		case reOrgReportReOrgConstants.GetAllPaged.SUCCESS :
			return {
				...state,
				isSending                : false,
				reOrgReportReOrgDeleted  : false,
				reOrgReportReOrgs        : action.payload.result,
				reOrgReportReOrgsCreated : false
			}
		case reOrgReportReOrgConstants.GetAllPaged.FAILURE :
			return {
				...state,
				error             : action.payload,
				isSending         : false,
				reOrgReportReOrgs : []
			}
		case reOrgReportReOrgConstants.GetAllPaged.CLEAR :
			return {
				...state,
				isAnyReOrgs          : false,
				isSending            : false,
				reOrgReportReOrgs    : [],
				reOrgReportReOrgsAll : []
			}
		case reOrgReportReOrgConstants.GetAllReOrgs.REQUEST :
			return {
				...state,
				isSending: true
			}
		case reOrgReportReOrgConstants.GetAllReOrgs.SUCCESS :
			return {
				...state,
				isSending            : false,
				reOrgReportReOrgsAll : action.payload.result
			}
		case reOrgReportReOrgConstants.GetAllReOrgs.FAILURE :
			return {
				...state,
				error                : action.payload,
				isSending            : false,
				reOrgReportReOrgsAll : []
			}
		case reOrgReportReOrgConstants.IsAnyReOrgs.REQUEST :
			return {
				...state,
				isAnyReOrgs : false,
				isSending   : true
			}
		case reOrgReportReOrgConstants.IsAnyReOrgs.SUCCESS :
			return {
				...state,
				isAnyReOrgs : action.payload.result,
				isSending   : false
			}
		case reOrgReportReOrgConstants.IsAnyReOrgs.FAILURE :
			return {
				...state,
				error       : action.payload,
				isAnyReOrgs : false,
				isSending   : false
			}
		default :
			return state
	}
}
