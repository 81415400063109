import {
	reAnswerFormsConstants
}from "./constants"
import i18n from "../../localization"
import isRu from "../../../../helpers/isRu"

const initialState = {
	isSending         : false,
	data              : [],
	currentItem       : {},
	error             : null,
	answerFormChanged : null
}

export default function (state = initialState, action){
	switch (action.type){
		case reAnswerFormsConstants.GetReAnswerForms.REQUEST:
			return {
				...state,
				isSending   : true,
				currentItem : []
			}
		case reAnswerFormsConstants.GetReAnswerForms.SUCCESS:
			return {
				...state,
				isSending   : false,
				currentItem : action.payload.result
			}
		case reAnswerFormsConstants.GetReAnswerForms.FAILURE:
			return {
				...state,
				isSending   : false,
				error       : action.payload,
				currentItem : []
			}
		case reAnswerFormsConstants.CreateAnswerForms.REQUEST:
		case reAnswerFormsConstants.DeleteAnswerForms.REQUEST:
		case reAnswerFormsConstants.RestoreAnswerForms.REQUEST:
		case reAnswerFormsConstants.UpdateAnswerForms.REQUEST:
			return {
				...state,
				isSending         : true,
				answerFormChanged : null
			}
		case reAnswerFormsConstants.CreateAnswerForms.FAILURE:
		case reAnswerFormsConstants.DeleteAnswerForms.FAILURE:
		case reAnswerFormsConstants.RestoreAnswerForms.FAILURE:
		case reAnswerFormsConstants.UpdateAnswerForms.FAILURE:
			return {
				...state,
				isSending         : false,
				error             : action.payload,
				answerFormChanged : null
			}
		case reAnswerFormsConstants.CreateAnswerForms.SUCCESS:
			return {
				...state,
				isSending         : false,
				answerFormChanged : action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null
			}
		case reAnswerFormsConstants.DeleteAnswerForms.SUCCESS:
			return {
				...state,
				isSending         : false,
				answerFormChanged : action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null
			}
		case reAnswerFormsConstants.UpdateAnswerForms.SUCCESS:
			return {
				...state,
				isSending         : false,
				answerFormChanged : action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null
			}
		case reAnswerFormsConstants.RestoreAnswerForms.SUCCESS:
			return {
				...state,
				isSending         : false,
				answerFormChanged : action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null
			}
		case reAnswerFormsConstants.getAnswerFormByNamePaged.REQUEST:
			return {
				...state,
				isSending: true
			}
		case reAnswerFormsConstants.getAnswerFormByNamePaged.SUCCESS:
			return {
				...state,
				isSending   : false,
				currentItem : action.payload
			}
		case reAnswerFormsConstants.getAnswerFormByNamePaged.FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}
		case reAnswerFormsConstants.GetReAnswerForms.CLEAR:
			return {
				...state,
				answerFormChanged : null,
				isSending         : false,
				currentItem       : {}

			}

			/*
			 * case reAnswerFormsConstants.GetReAnswerForm.REQUEST:
			 *   return {
			 *     ...state,
			 *     isSending: true,
			 *     currentItem: null,
			 *   };
			 * case reAnswerFormsConstants.GetReAnswerForm.SUCCESS:
			 *   return {
			 *     ...state,
			 *     isSending: false,
			 *     currentItem: action.payload.result,
			 *   };
			 * case reAnswerFormsConstants.GetReAnswerForm.FAILURE:
			 *   return {
			 *     ...state,
			 *     isSending: false,
			 *     error: action.payload,
			 *   };
			 * case reAnswerFormsConstants.GetReAnswerForm.CLEAR:
			 *   return {
			 *     ...state,
			 *     currentItem: null,
			 *   };
			 */
		default:
			return state
	}
}
