import {
  reFormTypesConstants
} from './constants'
import { i18n } from '../localization'
import isRu from '../../../helpers/isRu'

const initialState =
{
  isSending: false,
  currentItem: {},
  error: null,
  formTypesChanged: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case reFormTypesConstants.SEND_REFORMTYPES_REQUEST:
      return {
        ...state,
        isSending: true
      }
    case reFormTypesConstants.SEND_REFORMTYPES_SUCCESS:
      return {
        ...state,
        isSending: false,
        currentItem: action.payload
      }
    case reFormTypesConstants.SEND_REFORMTYPES_FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload
      }
    case reFormTypesConstants.createFormType.REQUEST:
    case reFormTypesConstants.deleteFormType.REQUEST:
    case reFormTypesConstants.updateFormType.REQUEST:
    case reFormTypesConstants.restoreFormType.REQUEST:
      return {
        ...state,
        isSending: true,
        formTypesChanged: null
      }
    case reFormTypesConstants.createFormType.FAILURE:
    case reFormTypesConstants.deleteFormType.FAILURE:
    case reFormTypesConstants.updateFormType.FAILURE:
    case reFormTypesConstants.restoreFormType.FAILURE:
      return {
        ...state,
        isSending: false,
        formTypesChanged: null,
        error: action.payload
      }
    case reFormTypesConstants.createFormType.SUCCESS:
      return {
        ...state,
        isSending: false,
        formTypesChanged: action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null
      }
    case reFormTypesConstants.deleteFormType.SUCCESS:
      return {
        ...state,
        isSending: false,
        formTypesChanged: action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null
      }
    case reFormTypesConstants.updateFormType.SUCCESS:
      return {
        ...state,
        isSending: false,
        formTypesChanged: action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null
      }
    case reFormTypesConstants.restoreFormType.SUCCESS:
      return {
        ...state,
        isSending: false,
        formTypesChanged: action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null
      }
    case reFormTypesConstants.getReFormTypes.CLEAR: 
      return {
        ...state,
        isSending: false,
        formTypesChanged: null,
        currentItem: {}
      }  
    default:
      return state
  }
}