export const reInterimReasonsConstants = {
	Change: {
		FAILURE : 'CHANGE_REINTERIMREASON_FAILURE',
		REQUEST : 'CHANGE_REINTERIMREASON_REQUEST',
		SUCCESS : 'CHANGE_REINTERIMREASON_SUCCESS'
	},
	Clear   : 'CLEAR_REINTERIMREASONS',
	GetList : {
		FAILURE : 'GET_LIST_REINTERIMREASONS_FAILURE',
		REQUEST : 'GET_LIST_REINTERIMREASONS_REQUEST',
		SUCCESS : 'GET_LIST_REINTERIMREASONS_SUCCESS'
	},
	GetPaged: {
		FAILURE : 'GET_PAGED_REINTERIMREASONS_FAILURE',
		REQUEST : 'GET_PAGED_REINTERIMREASONS_REQUEST',
		SUCCESS : 'GET_PAGED_REINTERIMREASONS_SUCCESS'
	}
}