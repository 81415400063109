export const reCountriesConstants = {
  SEND_RECOUNTRIES_REQUEST: 'SEND_RECOUNTRIES_REQUEST',
  SEND_RECOUNTRIES_SUCCESS: 'SEND_RECOUNTRIESS_SUCCESS',
  SEND_RECOUNTRIES_FAILURE: 'SEND_RECOUNTRIES_FAILURE',
  getReCountries : {
    CLEAR: 'GET_COUNTRIES_CLEAR',
  },
  createCountry : {
    REQUEST: 'CREATE_COUNTRY_REQUEST',
    SUCCESS: 'CREATE_COUNTRY_SUCCESS',
    FAILURE: 'CREATE_COUNTRY_FAILURE',
  },
  deleteCountry : {
    REQUEST: 'DELETE_COUNTRY_REQUEST',
    SUCCESS: 'DELETE_COUNTRY_SUCCESS',
    FAILURE: 'DELETE_COUNTRY_FAILURE',
  },
  updateCountry : {
    REQUEST: 'UPDATE_COUNTRY_REQUEST',
    SUCCESS: 'UPDATE_COUNTRY_SUCCESS',
    FAILURE: 'UPDATE_COUNTRY_FAILURE',
  },
  restoreCountry : {
    REQUEST: 'RESTORE_COUNTRY_REQUEST',
    SUCCESS: 'RESTORE_COUNTRY_SUCCESS',
    FAILURE: 'RESTORE_COUNTRY_FAILURE',
  }
}