export const reAppealViewTypesConstants = {
	CreateAppealViewTypes: {
		REQUEST : 'CREATE_APPEAL_VIEW_TYPES_REQUEST',
		SUCCESS : 'CREATE_APPEAL_VIEW_TYPES_SUCCESS',
		FAILURE : 'CREATE_APPEAL_VIEW_TYPES_FAILURE'
	},
	DeleteAppealViewTypes: {
		REQUEST : 'DELETE_APPEAL_VIEW_TYPES_REQUEST',
		SUCCESS : 'DELETE_APPEAL_VIEW_TYPES_SUCCESS',
		FAILURE : 'DELETE_APPEAL_VIEW_TYPES_FAILURE'
	},
	RestoreAppealViewTypes: {
		REQUEST : 'RESTORE_APPEAL_VIEW_TYPES_REQUEST',
		SUCCESS : 'RESTORE_APPEAL_VIEW_TYPES_SUCCESS',
		FAILURE : 'RESTORE_APPEAL_VIEW_TYPES_FAILURE'
	},
	UpdateAppealViewTypes: {
		REQUEST : 'UPDATE_APPEAL_VIEW_TYPES_REQUEST',
		SUCCESS : 'UPDATE_APPEAL_VIEW_TYPES_SUCCESS',
		FAILURE : 'UPDATE_APPEAL_VIEW_TYPES_FAILURE'
	},

	SEND_REAPPEALVIEWTYPES_FAILURE : 'SEND_REAPPEALVIEWTYPES_FAILURE',
	SEND_REAPPEALVIEWTYPES_REQUEST : 'SEND_REAPPEALVIEWTYPES_REQUEST',
	SEND_REAPPEALVIEWTYPES_SUCCESS : 'SEND_REAPPEALVIEWTYPES_SUCCESS'

}