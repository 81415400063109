import { i18n } from "../localization";
import { ReMessageTemplateTypeConstants } from "./constants";
import isRu from '../../../helpers/isRu'

const initialState = {
  isSending: false,
  currentItems: [],
  messageTemplateTypeChanged: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ReMessageTemplateTypeConstants.CLEAR_MODIFY_RESULT:
      return {
        ...state,
        isSending: false,
        messageTemplateTypeChanged: null,
        currentItems: [],
      };
    case ReMessageTemplateTypeConstants.GetMessageTemplatesTypesPaged.REQUEST:
    case ReMessageTemplateTypeConstants.CreateReMessageTemplateType.REQUEST:
    case ReMessageTemplateTypeConstants.UpdateReMessageTemplateType.REQUEST:
    case ReMessageTemplateTypeConstants.RestoreReMessageTemplateType.REQUEST:
    case ReMessageTemplateTypeConstants.DeleteReMessageTemplateType.REQUEST:
      return {
        ...state,
        isSending: true,
        messageTemplateTypeChanged: null,
      };
    case ReMessageTemplateTypeConstants.GetMessageTemplatesTypesPaged.SUCCESS:
      return {
        ...state,
        isSending: false,
        currentItems: action.payload.result,
      };
    case ReMessageTemplateTypeConstants.GetMessageTemplatesTypesPaged.FAILURE:
    case ReMessageTemplateTypeConstants.CreateReMessageTemplateType.FAILURE:
    case ReMessageTemplateTypeConstants.UpdateReMessageTemplateType.FAILURE:
    case ReMessageTemplateTypeConstants.DeleteReMessageTemplateType.FAILURE:
    case ReMessageTemplateTypeConstants.RestoreReMessageTemplateType.FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      };
    case ReMessageTemplateTypeConstants.CreateReMessageTemplateType.SUCCESS:
      return {
        ...state,
        isSending: false,
        messageTemplateTypeChanged: action.payload.result ? i18n.messageItemAddSuccses[isRu()] : ''
      };

    case ReMessageTemplateTypeConstants.UpdateReMessageTemplateType.SUCCESS:
      return {
        ...state,
        isSending: false,
        messageTemplateTypeChanged: action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : ''
      };
    case ReMessageTemplateTypeConstants.DeleteReMessageTemplateType.SUCCESS:
      return {
        ...state,
        isSending: false,
        messageTemplateTypeChanged: action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : ''
      };
    case ReMessageTemplateTypeConstants.RestoreReMessageTemplateType.SUCCESS:
      return {
        ...state,
        isSending: false,
        messageTemplateTypeChanged: action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : ''
      };
    default:
      return state;
  }
}
