export const reOrgStatusTypesConstants = {
	clear                 : "CLEAR_REORGSTATUS_TYPE",
	createReOrgStatusType : {
		FAILURE : "CREATE_REORGSTATUS_TYPE_FAILURE",
		REQUEST : "CREATE_REORGSTATUS_TYPE_REQUEST",
		SUCCESS : "CREATE_REORGSTATUS_TYPE_SUCCESS"
	},
	deleteReOrgStatusType: {
		FAILURE : "DELETE_REORGSTATUS_TYPE_FAILURE",
		REQUEST : "DELETE_REORGSTATUS_TYPE_REQUEST",
		SUCCESS : "DELETE_REORGSTATUS_TYPE_SUCCESS"
	},
	getAllPagedReOrgStatusTypes: {
		FAILURE : "GETALLPAGED_REORGSTATUS_TYPES_FAILURE",
		REQUEST : "GETALLPAGED_REORGSTATUS_TYPES_REQUEST",
		SUCCESS : "GETALLPAGED_REORGSTATUS_TYPES_SUCCESS"
	},
	restoreReOrgStatusType: {
		FAILURE : "RESTORE_REORGSTATUS_TYPE_FAILURE",
		REQUEST : "RESTORE_REORGSTATUS_TYPE_REQUEST",
		SUCCESS : "RESTORE_REORGSTATUS_TYPE_SUCCESS"
	},
	updateReOrgStatusType: {
		FAILURE : "UPDATE_REORGSTATUS_TYPE_FAILURE",
		REQUEST : "UPDATE_REORGSTATUS_TYPE_REQUEST",
		SUCCESS : "UPDATE_REORGSTATUS_TYPE_SUCCESS"
	}
}
