import {
	requests
}from '../../../../api/agent'

export default {
	createReOrgReReport        : (params) => requests.post('/reOrgReReports/create', params),
	createReOrgReReports       : (params) => requests.post('/reOrgReReports/createReReports', params),
	deleteReOrgReReport        : (params) => requests.post('/reOrgReReports/delete', params),
	deleteReOrgReReports       : (params) => requests.post('/reOrgReReports/deleteReReports', params),
	getActualKindsReOrgReports : (params) => requests.getWithParams('/reOrgReReports/getActualKindsReports', params),
	getAllPaged                : (params) => requests.getWithParams('/reOrgReReports/getAllPaged', params),
	getAllReports              : (params) => requests.getWithParams('/reOrgReReports/getAllReports', params),
	updateReOrgReReport        : (params) => requests.post('/reOrgReReports/update', params),
	uploadReportLevelsFromFile : (params) => requests.post('/reOrgReReports/createHierarchyLevelFromFile', params),
	uploadReportOrgsFromFile   : (params) => requests.post('/reOrgReportReOrgs/createFromFile', params)
}
