export const reIndexConstans = {
  
    CreateIndex: {
      REQUEST: "CREATE_INDEX_REQUEST",
      SUCCESS: "CREATE_INDEX_SUCCESS",
      FAILURE: "CREATE_INDEX_FAILURE",
    },
  
    GetAllPaged: {
      REQUEST: "GET_All_PAGED_REQUEST",
      SUCCESS: "GET_All_PAGED_SUCCESS",
      FAILURE: "GET_All_PAGED_FAILURE",
      CLEAR: "CLEAR_ALL_PAGED",
    },

    GetList: {
      REQUEST: "GET_LIST_REQUEST",
      SUCCESS: "GET_LIST_SUCCESS",
      FAILURE: "GET_LIST_FAILURE",
      CLEAR: "CLEAR_LIST",
    },
  
    RestoreIndex: {
      REQUEST: "RESTORE_INDEX_REQUEST",
      SUCCESS: "RESTORE_INDEX_SUCCESS",
      FAILURE: "RESTORE_INDEX_FAILURE",
    },
  
    UpdateIndex: {
      REQUEST: "UPDATE_INDEX_REQUEST",
      SUCCESS: "UPDATE_INDEX_SUCCESS",
      FAILURE: "UPDATE_INDEX_FAILURE",
    },
    DeleteIndex: {
      REQUEST: "DELETE_INDEX_REQUEST",
      SUCCESS: "DELETE_INDEX_SUCCESS",
      FAILURE: "DELETE_INDEX_FAILURE",
    },
  };